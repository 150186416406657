<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="p-1"
        >
          <b-row>
            <b-col cols="12">
              <div class="title">
                Estadísticas del día
              </div>
            </b-col>
            <b-col cols="12">
              <div class="title mb-1 text-center">
                MEDICAMENTOS ENFERMERIA
              </div>
              <b-row>
                <b-col
                  v-for="(item, index) in statistics_medicines"
                  :key="index"
                  cols="3"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-1">
                      <feather-icon
                        :icon="item.icon"
                        size="4.5x"
                        :style="'color:' + item.color"
                      />
                    </div>
                    <div class="text-center">
                      <div class="title2">
                        {{ item.number }}
                      </div>
                      <div class="title2">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="p-1"
        >
          <div class="title mb-1">
            Listado de camas con pacientes
          </div>
          <div class="d-flex align-items-center mb-2">
            <div class="title mr-1">
              Servicio
            </div>
            <v-select
              v-model="selected"
              label="text"
              :options="options"
              :reduce="option => option.value"
              class="select__service"
            />
          </div>
          <div class="">
            <tablee
              :table-data="patients"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import tablee from './components/LocationTable'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    tablee,
  },
  data() {
    return {
      selected: null,
			options: [
				{
					value: null,
					text: 'Seleccione',
				},
				{
					value: 1,
					text: 'HOSPITALIZACIÒN PISO 2',
				},
				{
					value: 2,
					text: 'URGENCIAS PISO 1',
				},
			],
      statistics_medicines: [
        {
          title: 'Pendientes',
          number: '10',
          color: '#00CFE8',
          icon: 'TargetIcon',
        },
        {
          title: 'Entregados',
          number: '10',
          color: '#28C76F',
          icon: 'CheckCircleIcon',
        },
        {
          title: 'No Entregados',
          number: '10',
          color: '#EA5455',
          icon: 'SlashIcon',
        },
        {
          title: 'Devoluciones',
          number: '10',
          color: '#7367F0',
          icon: 'DownloadIcon',
        },
      ],
      patients: [
        {
					title: 'CAMA_01',
					subtitle: '38°',
					risk: 'Alergia',
					patient: 'Cesar Diaz',
					eps: 'Salud total',
				},
      ],
    }
  },
}
</script>

<style lang="scss">
 @import '@core/scss/vue/libs/vue-select.scss';
  .title {
    font-weight: bold;
    font-size: 18px;
  }

  .title2 {
    font-weight: bold;
    font-size: 16px;
  }

  .select__service {
    width: 30%;
  }

  .icon_pending {
    color: #7367F0;
  }

</style>
