<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.title }}
            </div>
            <b-badge variant="dark">
              {{ data.item.subtitle }}
            </b-badge>
          </div>
        </div>
      </template>

      <!-- risk -->
      <template #cell(risk)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.risk }}</span>
        </div>
      </template>

      <!-- patient -->
      <template #cell(patient)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.patient }}</span>
        </div>
      </template>

      <!-- eps -->
      <template #cell(eps)="data">
        {{ data.item.eps }}
      </template>

      <template #cell(medicines)="">
        <div
          class="d-flex align-items-center"
          @click="patientMedications"
        >
          <div class="container-indicative-progress">
            <div
              style="width: 66%"
              class="container-progress"
            >
              <span class="value-progress">66%</span>
              <div class="progresss" />
            </div>
            <span class="indicative">2/3</span>
          </div>
        </div>
      </template>

      <!-- actions -->
      <template #cell(actions)="">
        <div class="d-flex align-items-center">
          <!-- <b-button
            v-b-tooltip.hover.top="'Const Medicamentosa'"
            size="sm"
            variant="primary"
            class="btn-icon margin-rigth"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button> -->
          <b-button
            v-b-tooltip.hover.top="'Perfil de farmaco'"
            size="sm"
            variant="warning"
            class="btn-icon margin-rigth"
            @click="redirect('pharmaco-profile')"
          >
            <feather-icon icon="LogOutIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Historial paciente'"
            size="sm"
            variant="secondary"
            class="btn-icon margin-rigth"
            @click="redirect('patientHistory')"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, VBModal, VBTooltip, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'company', label: 'CAMA' },
        { key: 'risk', label: 'RIESGO' },
        { key: 'patient', label: 'PACIENTE' },
        { key: 'eps', label: 'EPS' },
        { key: 'medicines', label: 'MEDICAMENTOS' },
        { key: 'actions', label: 'ACCIONES' },
      ],
    }
  },
  methods: {
    goFindPatient() {
      this.$router.push({ name: 'find-paciente' })
    },
    patientMedications() {
      this.$router.push({ name: 'patient-medication' })
    },
    redirect(name) {
      this.$router.push({ name: name })
    },
    modalExamen() {
      this.$bvModal.show('modal-assign-exam')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.margin-rigth{
  margin-right: 0.5rem;
}

.container-indicative-progress{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  cursor: pointer;
}
.container-progress{
  height: 1rem;
  width: 100%;
  background-color: #F5F5F5;
  position: relative;
  overflow: hidden;
  border-radius: 0.4rem;
  .value-progress{
    position: absolute;
    text-align: center;
    right: 0rem;
    left: 0rem;
    font-size: .7rem;
    top: 0rem;
    font-weight: bold;
  }
  .progresss{
    background-color: #30CFE8;
    height: 100%;
    width: 33%;
  }
}
.dieta {
  background-color: #7367F0;
}
.indicative{
  margin-top: .2rem;
}

</style>
